import React from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import makeAnimated from "react-select/animated";
import { Box, Grid, Paper } from "@material-ui/core";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "../../../assets/css/Modal.css";
import { styles } from "../common/styles";
import { renderButton, renderText } from "../common/DisplayComponent";
import QuestionTypeModal from "components/Modals/QuestionTypeModal";

const Step4 = ({
  submitting,
  stepCount,
  handleNext,
  handlePrev,
  handleQuestionChange,
  handleAnswerChange,
  handleScoreChange,
  handleOptionChange,
  handleAddInput,
  handleRemoveInput,
  handleAddOption,
  handleRemoveOption,
  handleTitleChange,
  handleDescriptionChange,
  isEditPage,
  onOrderChange = () => {},
  correctAnswerFeedbackChange,
  incorrectAnswerFeedbackChange
}) => {
  const animatedComponents = makeAnimated();
  const [openCategoryModal, setOpenCategoryModal] = React.useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result, stepCount) => {
    if (!result.destination) return;
    onOrderChange(
      stepCount,
      reorder(stepCount?.questions, result.source.index, result.destination.index)
    );
  };

  const toggleCategoryModal = () => {
    setOpenCategoryModal((open) => !open);
  };

  const onSubmitHandler = (questionType) => {
    handleAddInput(stepCount, {
      ...questionType,
      id: uuidv4()
    });
  };

  return (
    <Paper style={styles.steps}>
      <Box mt={2} mb={2}>
        {renderText({
          label: "Mixed Type",
          type: "h6",
          color: "textPrimary",
          align: "center"
        })}
      </Box>

      {openCategoryModal && (
        <QuestionTypeModal
          toggleModal={toggleCategoryModal}
          isOpen={openCategoryModal}
          onSubmitHandler={onSubmitHandler}
          category={null}
        />
      )}
      <Row>
        <Col sm="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-cabinet-title">
              Title
            </label>
            <Input
              className="form-control-alternative"
              required
              onChange={(e) => handleTitleChange(stepCount, e.target.value)}
              value={stepCount?.title}
              id="input-cabinet-title"
              type="text"
            />
          </FormGroup>
        </Col>
        <Col sm="12">
          <FormGroup>
            <label className="form-control-label" htmlFor="input-cabinet-title">
              Test Instructions
            </label>
            <Input
              className="form-control-alternative"
              onChange={(e) => handleDescriptionChange(stepCount, e.target.value)}
              value={stepCount?.description}
              id="input-cabinet-title"
              type="textarea"
            />
          </FormGroup>
        </Col>

        {/* <Col md={6}>
          <FormGroup>
            <label className="form-control-label" htmlFor="correct-answer-feedback">
              Correct Answer Feedback
            </label>
            <Input
              min={0}
              className="form-control-alternative"
              onChange={(e) => correctAnswerFeedbackChange(stepCount, e.target.value)}
              value={stepCount?.correctAnswerFeedback || ""}
              id="correct-answer-feedback"
              type="textarea"
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <label className="form-control-label" htmlFor="incorrect-answer-feedback">
              Incorrect Answer Feedback
            </label>
            <Input
              min={0}
              className="form-control-alternative"
              onChange={(e) => incorrectAnswerFeedbackChange(stepCount, e.target.value)}
              value={stepCount?.incorrectAnswerFeedback || ""}
              id="incorrect-answer-feedback"
              type="textarea"
            />
          </FormGroup>
        </Col> */}
      </Row>

      <hr style={{ margin: "0 10rem" }} />

      <Row>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, stepCount)}>
          <Droppable droppableId="verticle-list">
            {(droppableProvided, droppableSnapshot) => {
              return (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  style={{
                    width: "100%"
                  }}
                >
                  {stepCount?.questions?.length === 0 && (
                    <Col
                      sm={12}
                      className="d-flex justify-content-start align-items-center mt-3"
                      onClick={() => setOpenCategoryModal(true)}
                    >
                      <span onClick={(e) => e.stopPropagation()}>Add New Question</span>
                      <Button
                        type="button"
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                          transition: "none",
                          padding: "0"
                        }}
                      >
                        <i
                          className="fas fa-plus add_test_section_icon ml-2"
                          style={{
                            padding: "5px 7px",
                            trasition: "all 0.3s ease-in-out",
                            borderRadius: "5px"
                          }}
                        ></i>
                      </Button>
                    </Col>
                  )}

                  {stepCount?.questions?.map((item, index) => {
                    return (
                      <Draggable
                        key={item?.id + stepCount?.id}
                        draggableId={item?.id.toString()}
                        index={index}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <Row className="mx-0">
                              <Col sm="10">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-cabinet-title"
                                  >
                                    Question
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    required
                                    onChange={(e) =>
                                      handleQuestionChange(stepCount, item?.id, e.target.value)
                                    }
                                    value={item?.title}
                                    id="input-cabinet-title"
                                    type="textarea"
                                  />
                                </FormGroup>
                              </Col>
                              <Col
                                sm={1}
                                xs={6}
                                className="d-flex justify-content-center align-items-center"
                                onClick={() => setOpenCategoryModal(true)}
                              >
                                <Button
                                  type="button"
                                  style={{
                                    background: "transparent",
                                    boxShadow: "none",
                                    border: "none",
                                    transition: "none",
                                    padding: "0"
                                  }}
                                >
                                  <i
                                    className="fas fa-plus add_test_section_icon"
                                    style={{
                                      padding: "5px 7px",
                                      trasition: "all 0.3s ease-in-out",
                                      borderRadius: "5px"
                                    }}
                                  ></i>
                                </Button>
                              </Col>
                              {
                                <Col
                                  sm={1}
                                  xs={6}
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => handleRemoveInput(stepCount, item?.id)}
                                >
                                  <Button
                                    type="button"
                                    style={{
                                      background: "transparent",
                                      boxShadow: "none",
                                      border: "none",
                                      transition: "none",
                                      padding: "0"
                                    }}
                                  >
                                    <i
                                      className="fas fa-times remove_test_section_icon"
                                      style={{
                                        padding: "5px 9px",
                                        trasition: "all 0.3s ease-in-out",
                                        borderRadius: "5px"
                                      }}
                                    ></i>
                                  </Button>
                                </Col>
                              }

                              {item?.type === "mcq" && (
                                <Row className="w-100 px-4">
                                  Options
                                  {item?.options?.filter((item) => item?.id !== "No Response")
                                    .length === 0 && (
                                    <Col
                                      className=""
                                      onClick={() =>
                                        handleAddOption(stepCount, item?.id, {
                                          id: uuidv4(),
                                          value: "",
                                          label: ""
                                        })
                                      }
                                    >
                                      <Button
                                        type="button"
                                        style={{
                                          background: "transparent",
                                          boxShadow: "none",
                                          border: "none",
                                          transition: "none",
                                          padding: "0"
                                        }}
                                      >
                                        <i
                                          className="fas fa-plus add_test_option_icon"
                                          style={{
                                            padding: "5px 7px",
                                            marginBottom: "1rem",
                                            trasition: "all 0.3s ease-in-out",
                                            borderRadius: "5px"
                                          }}
                                        ></i>
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              )}

                              {item?.type === "mcq" &&
                                item?.options
                                  ?.filter((item) => item?.id !== "No Response")
                                  .map((option, index) => {
                                    return (
                                      <React.Fragment key={option?.id + stepCount?.id}>
                                        <Col sm={8}>
                                          <FormGroup>
                                            <Input
                                              className="form-control-alternative"
                                              required
                                              onChange={(e) =>
                                                handleOptionChange(
                                                  stepCount,
                                                  item?.id,
                                                  option?.id,
                                                  e.target.value
                                                )
                                              }
                                              value={option?.value}
                                              id="input-cabinet-title"
                                              type="text"
                                            />
                                          </FormGroup>
                                        </Col>
                                        {item?.options?.length < 5 && (
                                          <Col
                                            sm={1}
                                            xs={6}
                                            className="d-flex justify-content-center"
                                            onClick={() =>
                                              handleAddOption(stepCount, item?.id, {
                                                id: uuidv4(),
                                                value: "",
                                                label: ""
                                              })
                                            }
                                          >
                                            <Button
                                              type="button"
                                              style={{
                                                background: "transparent",
                                                boxShadow: "none",
                                                border: "none",
                                                transition: "none",
                                                padding: "0"
                                              }}
                                            >
                                              <i
                                                className="fas fa-plus add_test_option_icon"
                                                style={{
                                                  padding: "5px 7px",
                                                  marginBottom: "1rem",
                                                  trasition: "all 0.3s ease-in-out",
                                                  borderRadius: "5px"
                                                }}
                                              ></i>
                                            </Button>
                                          </Col>
                                        )}
                                        <Col
                                          sm={1}
                                          xs={6}
                                          className="d-flex justify-content-center"
                                          onClick={() =>
                                            handleRemoveOption(
                                              stepCount,
                                              item?.id,
                                              option?.id,
                                              option
                                            )
                                          }
                                        >
                                          <Button
                                            type="button"
                                            style={{
                                              background: "transparent",
                                              boxShadow: "none",
                                              border: "none",
                                              transition: "none",
                                              padding: "0"
                                            }}
                                          >
                                            <i
                                              className="fas fa-times remove_test_section_icon"
                                              style={{
                                                padding: "5px 9px",
                                                marginBottom: "1rem",
                                                trasition: "all 0.3s ease-in-out",
                                                borderRadius: "5px"
                                              }}
                                            ></i>
                                          </Button>
                                        </Col>
                                      </React.Fragment>
                                    );
                                  })}

                              {item?.type !== "text" && (
                                <Col sm={12}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-cabinet-title"
                                    >
                                      Answer
                                    </label>
                                    <Select
                                      id="input-email"
                                      components={animatedComponents}
                                      value={
                                        item?.options?.find((opt) => opt.value === item.answer) ||
                                        {}
                                      }
                                      closeMenuOnSelect
                                      onChange={(e) =>
                                        handleAnswerChange(stepCount, item?.id, e.value)
                                      }
                                      options={item?.options?.filter(
                                        (item) => item?.id !== "No Response"
                                      )}
                                    />
                                  </FormGroup>
                                </Col>
                              )}

                              <Col sm={12}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-cabinet-title"
                                  >
                                    Score
                                  </label>
                                  <Input
                                    min={0}
                                    className="form-control-alternative"
                                    required
                                    onChange={(e) => {
                                      const score = Number(e.target.value);
                                      if (!isNaN(score) && score >= 0) {
                                        handleScoreChange(stepCount, item?.id, score);
                                      }
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    value={item?.score ?? ""}
                                    id="input-cabinet-title"
                                    type="number"
                                    step="0.01"
                                    lang="en"
                                  />
                                </FormGroup>
                              </Col>

                              {["mcq", "true-false"].includes(item?.type) ? (
                                <>
                                  <Col md={6}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="correct-answer-feedback"
                                      >
                                        Correct Answer Feedback
                                      </label>
                                      <Input
                                        min={0}
                                        className="form-control-alternative"
                                        onChange={(e) =>
                                          correctAnswerFeedbackChange(
                                            stepCount,
                                            item?.id,
                                            e.target.value
                                          )
                                        }
                                        value={item?.correctAnswerFeedback || ""}
                                        id="correct-answer-feedback"
                                        type="textarea"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="correct-answer-feedback"
                                      >
                                        Incorrect Answer Feedback
                                      </label>
                                      <Input
                                        min={0}
                                        className="form-control-alternative"
                                        onChange={(e) =>
                                          incorrectAnswerFeedbackChange(
                                            stepCount,
                                            item?.id,
                                            e.target.value
                                          )
                                        }
                                        value={item?.incorrectAnswerFeedback || ""}
                                        id="correct-answer-feedback"
                                        type="textarea"
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              ) : null}

                              <Col xs={12}>
                                <hr />
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Row>

      <Grid container component={Box} justify="flex-end" mt={2} p={2}>
        {stepCount?.prev && (
          <Box ml={2}>
            {renderButton({
              label: "Back",
              color: "default",
              onClick: handlePrev
            })}
          </Box>
        )}
        {stepCount?.next && (
          <Box ml={2}>{renderButton({ label: "Next", onClick: () => handleNext(stepCount) })}</Box>
        )}
        {stepCount?.submit && (
          <Box ml={2}>
            {renderButton({
              label: submitting ? "Submitting..." : "Submit",
              onClick: () => {},
              type: "submit",
              disabled: submitting
            })}
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

export default Step4;
